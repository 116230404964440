<template>
  <CopyToRegister />
</template>

<script>
import CopyToRegister from "@/components/auth/CopyToRegister";
export default {
  name: "Register",
  components: { CopyToRegister }
};
</script>

<style scoped></style>
