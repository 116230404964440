<template>
  <v-dialog :value="true" persistent max-width="600px" min-width="360px">
    <div>
      <v-tabs show-arrows background-color="primary" icons-and-text dark grow>
        <v-tabs-slider color="primary darken-4"></v-tabs-slider>

        <v-tab>
          <v-icon large>mdi-account-outline</v-icon>
          <div class="caption py-1">Register</div>
        </v-tab>

        <v-tab-item>
          <v-card v-if="loading" class="px-4">
            <LoadingBar />
          </v-card>
          <div v-else-if="error !== false" class="error lighten-1">
            <p class="pa-5 text-center" v-html="error"></p>
          </div>
          <v-card v-else class="px-4">
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                @submit.prevent="submit"
              >
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      name="first_name"
                      v-model="first_name"
                      :rules="rules.required"
                      label="First Name"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      name="last_name"
                      v-model="last_name"
                      :rules="rules.required"
                      label="Last Name"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      name="fax"
                      v-model="fax"
                      v-mask="$masks.phone"
                      label="Fax (optional)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      name="username"
                      v-model="username"
                      :rules="rules.email"
                      label="Email"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      label="Password"
                      type="password"
                      v-model="password"
                      :rules="rules.password"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Password (confirm)"
                      type="password"
                      v-model="password_confirm"
                      v-on:keyup.enter="submit"
                      :rules="rules.password_confirm"
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="6" xsm="12"></v-col>
                  <v-spacer></v-spacer>
                  <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
                    <v-btn
                      x-large
                      block
                      :disabled="!valid"
                      color="primary"
                      @click="submit"
                    >
                      Register
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import rules from "@/utils/rules";
import LoadingBar from "@/components/loaders/LoadingBar";

export default {
  name: "CopyToRegister",
  components: { LoadingBar },
  data() {
    return {
      loading: true,
      valid: true,
      error: false,
      password_confirm: "",
      rules: {
        required: [rules.required],
        password: [rules.required, rules.minLength(8)],
        email: [rules.required, rules.email],
        password_confirm: [
          rules.required,
          v => v === this.password || "Passwords do not match"
        ]
      }
    };
  },
  computed: {
    ...mapFields("auth", ["username", "password"]),
    ...mapFields("auth", [
      "copyToRegistration.data.first_name",
      "copyToRegistration.data.last_name",
      "copyToRegistration.data.fax"
    ]),
    ...mapState("auth", ["copyToRegistration"])
  },
  methods: {
    ...mapActions("auth", ["copyToFetch", "copyToRegister"]),
    submit() {
      if (!this.$refs.form.validate()) return false;
      this.copyToRegister(this.$route.params.token).then(res => {
        if (!res) {
          this.error = this.copyToRegistration.error;
        }
      });
    }
  },
  mounted() {
    this.copyToFetch(this.$route.params.token).then(res => {
      if (res) {
        this.username = this.copyToRegistration.data.email;
      } else {
        this.error = this.copyToRegistration.error;
      }
      // And we're always done loading
      this.loading = false;
    });
  }
};
</script>

<style scoped></style>
